export const TOKEN_PREFIX = window.APP_CONFIG.TOKEN_PREFIX
export const BLOCK_EXPLORER = window.APP_CONFIG.BLOCK_EXPLORER
export const COLORS = [
  "#1095C1",
  "#9410C1",
  "#C13C10",
  "#3CC110",
  "#1BE470",
  "#1B2BE4",
  "#E41B8F",
  "#E4D41B",
]
